<template>
  <div>
      <LiefengContent>
        <template v-slot:title>{{'短信链接转换率统计'}}</template>
        <template v-slot:toolsbarRight>
            <div class="select-view" style="margin-right:20px">
                <div @click="changeMenu(index)" :class="menuIndex == index ? 'item item-active' : 'item'" v-for="(item,index) in menList" :key="index">{{item.value}}</div>
            </div>
            <div v-if="menuIndex == 2" class="data-select" style="margin-right:20px;display:flex">
                <DatePicker v-model="searchDate" type="daterange" split-panels placeholder="选择查询时间" style="width: 200px"></DatePicker>
                <Button type="primary" @click="searchBtn">查询</Button>
            </div>
            
        </template>
        <template v-slot:contentArea>
            <Card style="margin:20px 0;">
                <TitleColor title='所有链接点击量'></TitleColor>
                <div class="line-div">
                    <VeLine :data="lineUsage.lineData" :settings="lineUsage.settings"></VeLine>
                </div>
                <div class="click-num">
                    <div class="item">全部短信发送次数: {{clickData.sendNum || 0}}</div>
                    <div class="item">链接点击量: {{clickData.pointNum || 0}}</div>
                    <div class="item">转换率: {{clickData.conversionRate || '0%'}}</div>
                </div>

                <div class="table-div">
                     <Table
                        :loading="table.loading"
                        ref="selection"
                        stripe
                        :height="table.tableHeight"
                        :columns="table.talbeColumns"
                        :data="table.tableData"
                        style="margin-top:20px"
                    ></Table>
                    <Page
                        class="page"
                        show-total
                        @on-change="currentPage"
                        @on-page-size-change="hadlePageSize"
                        :total="table.total"
                        size="small"
                        :page-size.sync="pageSize"
                        :current="page"
                        show-elevator
                    />
                </div>
            </Card>
        </template>
      </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent";
import TitleColor from '../portalSystem/components/TitleColor';
import VeLine from '../portalSystem/components/VeLine';
export default {
    components:{
        LiefengContent,
        TitleColor,
        VeLine
    },
    data(){
        return{
            table:{
                loading:false,
                talbeColumns:[
                    {
                        title: "短信内容",
                        key: "msgData",
                        minWidth: 200,
                        align: "center"
                    },
                    {
                        title: "短信发送人数",
                        key: "sendNum",
                        minWidth: 200,
                        align: "center"
                    },
                    {
                        title: "链接点击量",
                        key: "pointNum",
                        minWidth: 200,
                        align: "center"
                    },
                    {
                        title: "转换率",
                        key: "conversionRate",
                        minWidth: 200,
                        align: "center"
                    },
                    {
                        title: "时间",
                        key: "gmtCreate",
                        minWidth: 200,
                        align: "center",
                        render:(h,params)=>{
                            return h('div',{},params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate),'yyyy-MM-dd hh:mm:ss') : '')
                        }
                    },
                ],
                tableData:[],
                total:0,
                tableHeight:''
            },
            menuIndex:0,
            menList:[
                {value:'最近7天'},
                {value:'最近30天'},
                {value:'自定义时间'}
            ],
            
            // 绑定的date数据
            searchDate:[],
            // 链接点击量
            lineUsage:{
                lineData:{},
                settings:{
                    area: true,
                    itemStyle: {
                    //面积图颜色设置
                    color: {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                        {
                            offset: 0,
                            color: "rgba(254,106,172,0.6)" // 0% 处的颜色
                        },
                        {
                            offset: 1,
                            color: "rgba(255,139,119,0.1)" // 100% 处的颜色
                        }
                        ],
                        globalCoord: false // 缺省为 false
                    }
                    }
                }
            },
            clickData:{},
            page:1,
            pageSize:20,
            
            params:{}
        }
    },
    beforeMount() {
      this.table.tableHeight = String(window.innerHeight - 238);
    },
    async created(){
       this.params = {
           date:'7'
       }
       this.lineUsage.lineData = {
            columns:['日期','点击量'],
            rows:[]
       }
       await this.getPointNumByGmtCreate(this.params)
        await this.getSendNumAndPointNum(this.params)
        await this.getTableData(this.params)
        // 生命周期，dom元素加载完之后触发，进页面需要显示table数据，需要获取表格接口
        
    },
    methods:{
        // 方法部分
        currentPage(val){
            this.page = val
            this.getTableData(this.params)
        },
        hadlePageSize(val){
            this.pageSize = val
            this.getTableData(this.params)
        },
        // 改变菜单change事件
       async changeMenu(index){
            this.menuIndex = index
            this.params = {}
            this.searchDate = []
            this.page = 1
            this.pageSize = 20
            if(index == 0){
                // 7天
                this.params.date = '7'
                 await this.getPointNumByGmtCreate(this.params)
                  await this.getSendNumAndPointNum(this.params)
                  await this.getTableData(this.params)
            }else if(index == 1){
                // 30天
                this.params.date = '30'
                 await this.getPointNumByGmtCreate(this.params)
                 await this.getSendNumAndPointNum(this.params)
                 await this.getTableData(this.params)
            }      
        },
        // 点击查询按钮
       async searchBtn(){
            if(this.searchDate[0] != ''){
                // 如果不为空，处理时间查询
                this.params = {
                    startDate:this.$core.formatDate(new Date(this.searchDate[0]),'yyyy-MM-dd'),
                    endDate:this.$core.formatDate(new Date(this.searchDate[1]),'yyyy-MM-dd')
                }
                await this.getPointNumByGmtCreate(this.params)
                await this.getSendNumAndPointNum(this.params)
                await this.getTableData(this.params)
            }else{
                // 如果为空，不处理时间查询
                this.$Message.warning({
                    content:'请选择时间',
                    background:true
                })
                return
            }
        },
        // 获取所有链接点击量
        async getPointNumByGmtCreate(parmas){
           this.lineUsage.lineData.rows = []
           let res = await this.$get('/syshequ/api/sytop/pc/behavior/getPointNumByDate',{
               orgCode:parent.vue.loginInfo.userinfo.orgCode,
               ...parmas
           })
           if(res.code == 200){
               if(res.dataList && res.dataList.length){
                   res.dataList.map(item =>{
                       this.lineUsage.lineData.rows.push({
                           '日期':item.date,
                           '点击量':item.pointNum
                       })
                   })
               }
           }else{
               this.$Message.error({
                   content:'获取所有链接点击量失败',
                   background:true
               })
               return
           }
        },
        // 获取短信发送次数接口
        async getSendNumAndPointNum(parmas){
            this.clickData = {}
           let res = await this.$get('/syshequ/api/sytop/pc/behavior/getSendNumAndPointNum',{
               orgCode:parent.vue.loginInfo.userinfo.orgCode,
               ...parmas
           })
           if(res.code == 200){
               if(res.data){
                   this.clickData = res.data
               }
           }else{
               this.$Message.error({
                   content:'获取转换率数据失败',
                   background:true
               })
               return
           }
        },
        // 获取短信table表
        async getTableData(params){
            this.table.tableData = []
            let res = await this.$get('/syshequ/api/sytop/pc/behavior/selectLinkBehaviorPage',{
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
                page:this.page,
                pageSize:this.pageSize,
                ...params
            })
            if(res.code == 200){
                if(res.dataList && res.dataList.length) {
                    this.table.tableData = res.dataList
                    this.table.total = res.maxCount
                    }
            }else{
                this.$Message.error({
                   content:'获取短信内容表格失败',
                   background:true
               })
               return
            }
        }
        // 接口部分
        // 获取分页接口
       
    },
    
}
</script>

<style lang="less" scoped>
.select-view{
    // width: 200px;
    height: 30px;
    display: flex;
    .item{
        width: 100px;
        height: 30px;
        line-height:30px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        &:nth-child(2){
            border-left: none
        }
        &-active{
            background:#2d8cf0 ;
            color: #ffffff;
        }
    }
}
.line-div{
    width: 100%;
    height: 100%;
    margin-top: 20px;
    // border-top: 1px dashed #ccc;
    padding-top: 10px;
    // &:nth-last-child(1){
    //     border-bottom: none;   
    // }
}
.click-num{
    width: 100%;
    height: 60px;
    line-height: 60px;
    display: flex;
    background-color: rgb(213, 231, 255);
    .item{
        margin: 0 20px;
    }
}
.page{
    padding: 5px 0;
   text-align: right;
}
</style>